import pdf1 from './carta_y_anexo.pdf';
import pdf2 from './constancias_sobres_pago_envíos_DHL.pdf';
import pdf3 from './imagenes_actuales.pdf';
import pdf4 from './justificantes_de_salud.pdf';
import pdf5 from './denuncias_CONANP_2024.pdf';

const content = {
  pdf1,
  pdf2,
  pdf3,
  pdf4,
  pdf5,
};

export default content;
