/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import styles from './anexos.module.scss';

import content from '../assets/2024'
import { Title, PDF, Loading } from './components';

const Updates2024 = () => {
  return (
    <div className={styles.container}>
      <Loading />
      <Title text="Carta para la Canciller Alicia Bárcena Ibarra y anexo" />
      {content?.pdf1 && <PDF file={content?.pdf1} title="Carta para la Canciller Alicia Bárcena Ibarra y anexo" />}
      <Title text="Constancias de sobres y pago de envíos de las cartas vía DHL" />
      {content?.pdf2 && <PDF file={content?.pdf2} title="Constancias de sobres y pago de envíos de las cartas vía DHL" />}
      <Title text="Imagenes actuales de anuncios y 2 Guayacánes, una Ceiba" />
      {content?.pdf3 && <PDF file={content?.pdf3} title="Imagenes actuales de anuncios y 2 Guayacánes, una Ceiba" />}
      <Title text="Justificantes de salud" />
      {content?.pdf4 && <PDF file={content?.pdf4} title="Justificantes de salud" />}
      <Title text="Denuncias de la CONANP 2024 en tiempos de la 4ta Transformación" />
      {content?.pdf5 && <PDF file={content?.pdf5} title="Denuncias de la CONANP 2024 en tiempos de la 4ta Transformación." />}
    </div>
  );
}

export default Updates2024;
